import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { HeaderSectionBlue, ProximamenteStyle, StoreIcons } from '../../styles';

export default function Proximamente( {logos, gif}) {
    let isMobile = false
    const {mobile_animation} = gif.mobile
    console.log(logos.logo_android)
    console.log(isMobile);
  return (
    <ProximamenteStyle>
        <HeaderSectionBlue>¡La app UMobile ha llegado!</HeaderSectionBlue>
        <div className='container-prox' >
            {/* <StaticImage className="img-phone-1" src='../../images/img-phones.png' alt="phones"/> */}
            {/* <img src={gifMobile} /> */}
            <div className='container-mobile' >
            

            {/* <img className='gif-mobile' src={gif.mobile_fija} alt="animation" /> */}
            <img className='gif_1' src={gif.mobile_1} alt="animation" />
            <img className='gif_2' src={gif.mobile_2} alt="animation" />
            <img className='gif_3' src={gif.mobile_3} alt="animation" />
            <img className='gif_4' src={gif.mobile_4} alt="animation" />
            </div>
            <div className="proximamente_description">
                <p style={{
                    color: 'white',
                    fontWeight: '400',
                    fontSize: '20px',
                    lineHeight: '32px',
                    fontStyle: 'normal',
                }}>Esos productos que tu negocio necesita o ese carro que siempre has querido al alcance de un solo click.</p>
                { isMobile ? <StaticImage className="img-phone-2" src='../../images/img-phones.png' alt="phones"/> : <></>}

                <StoreIcons className="buttons" style={{
                    margin: '0 0 45px 0',
                    justifyContent:'center'
                }}>
                    <div><a href='https://play.google.com/store/apps/details?id=com.leaseforu.uweb' target="_blank"><img src={logos.logo_android} height="35px"  style={{maxWidth: '90px'}} alt='app'/></a></div>
                    <div><a href='https://apps.apple.com/mx/app/leaseforu/id1530250409' target="_blank"><img src={logos.logo_ios} height="35px" style={{maxWidth: '90px'}}  alt='app'/></a></div>
                </StoreIcons>
               
            </div>
        </div>
    </ProximamenteStyle>
  )
}
