import { textAlign } from '@mui/system'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { FooterStyle, NetworkSocialIcons } from '../../styles'

export default function Footer({icons}) {
  return (
    <FooterStyle>
        <div className='columns'>
            <div>
                <p className='title'>Contáctanos</p>
                {/* <div className='option'><img src={icons.icon_wa} alt="phone"/><p>(55) 5545 4040</p></div> */}
                <div className='option'><img src={icons.icon_email} alt="email"/><p>info@leaseforu.com</p></div>
                <div className='option'><img src={icons.icon_site} alt="site"/><p>{process.env.GATSBY_DOMAIN}</p></div>
                <div className='option-loc'><img src={icons.icon_location} alt="location"/><p>Av. el Derby 254, oficina 1702<br/>Santiago de Surco, 15023<br/>Perú</p></div>
            </div>
            <div>
                <p className='title'>Información legal</p>
                <div className='option'><p className="without-icon">Términos y condiciones</p></div>
                <div className='option'><Link to='/aviso'><p className="without-icon">Aviso de privacidad</p></Link></div>
                <div style={{
                        marginTop: '30%',
                        textAlign: 'center'
                }}>
                    <StaticImage src='../../images/SELLO-BIVA.png' style={{height: '130px', width: '130px'}}/>
                </div>
            </div>
            <div>
                <p className='title'>Siguenos en nuestras redes</p>
                <NetworkSocialIcons>
                    <Link to="https://www.facebook.com/profile.php?id=100064230634432" target="_blank" ><img src={icons.btn_facebook} alt="btn-facebook" /></Link>
                    <Link to="https://twitter.com/lease_for_u" target="_blank" ><img src={icons.btn_twitter} alt="btn-twitter" /></Link>
                    <Link to="https://www.linkedin.com/company/lease-for-u/mycompany/" target="_blank" ><img src={icons.btn_linkedin} alt="btn-linkedin" /></Link>
                    <Link to="https://open.spotify.com/episode/6Mp9QjdZCDRoIfQouqKnFv?si=5dlz0XpvQkGeceGM8g9zZg" target="_blank" ><img src={icons.btn_spotify} alt="btn-spotify" /></Link>
                </NetworkSocialIcons>
                
            </div>
        </div>
        <p style={{fontSize: "0.7rem"}}>Copyright 2022 Lease For You S.A. de C.V.</p>
    </FooterStyle>
  )
}
