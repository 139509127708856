import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Seo from "../components/seo"
import Home from "../components/sections/home"
import Cotizador from "../components/sections/cotizador"
import Nosotros from "../components/sections/nosotros"
import Requisitos from "../components/sections/requisitos"
import Comunidad from "../components/sections/comunidad"
import Footer from "../components/sections/footer"
import 'swiper/css';
import "swiper/css/pagination";
import { StaticImage } from "gatsby-plugin-image"
import Header from "../components/header"
import AboutUs from "../components/sections/aboutUs"

import "@fontsource/montserrat";
import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/900.css";
import "@fontsource/montserrat/100-italic.css"
import "@fontsource/montserrat/200-italic.css"
import "@fontsource/montserrat/300-italic.css"
import "@fontsource/montserrat/400-italic.css"
import "@fontsource/montserrat/500-italic.css"
import "@fontsource/montserrat/600-italic.css"
import "@fontsource/montserrat/700-italic.css"
import "@fontsource/montserrat/800-italic.css"

import "./../styles/styles.scss"
import Proximamente from "../components/sections/proximamente"
import RequisitosPeru from "../components/sections/requisitos_peru"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query MyQuery {
      allFile {
        edges {
          node {
            publicURL
            name
            ext
          }
        }
      }
  }`)


  const logo = data.allFile.edges.filter(file => (file.node.name === 'leaseforu-logo'))[0] || null;
  const bgHome = data.allFile.edges.filter(file => (file.node.name === 'background-home'))[0] || null;
  const bgCotizador = data.allFile.edges.filter(file => (file.node.name === 'background-u'))[0] || null;
  const bgRequisitos = data.allFile.edges.filter(file => (file.node.name === 'img-persona-fisica'))[0] || null;

  console.log(logo)
  const icons = {
    btn_playstore: data.allFile.edges.filter(file => (file.node.name === 'btn-playstore'))[0].node.publicURL || null,
    btn_appstore: data.allFile.edges.filter(file => (file.node.name === 'btn-appstore'))[0].node.publicURL || null,
    btn_appgallery: data.allFile.edges.filter(file => (file.node.name === 'btn-appgallery'))[0].node.publicURL || null,
    btn_facebook: data.allFile.edges.filter(file => (file.node.name === 'btn-facebook'))[1].node.publicURL || null,
    btn_twitter: data.allFile.edges.filter(file => (file.node.name === 'btn-twitter'))[1].node.publicURL || null,
    btn_linkedin: data.allFile.edges.filter(file => (file.node.name === 'btn-linkedin'))[1].node.publicURL || null,
    btn_spotify: data.allFile.edges.filter(file => (file.node.name === 'btn-spotify'))[1].node.publicURL || null,
    btn_wa: data.allFile.edges.filter(file => (file.node.name === 'btn-whatsapp'))[1].node.publicURL || null,
    icon_wa: data.allFile.edges.filter(file => (file.node.name === 'icon-whatsapp'))[0].node.publicURL || null,
    icon_email: data.allFile.edges.filter(file => (file.node.name === 'icon-email'))[1].node.publicURL || null,
    icon_site: data.allFile.edges.filter(file => (file.node.name === 'icon-site'))[1].node.publicURL || null,
    icon_location: data.allFile.edges.filter(file => (file.node.name === 'icon-location'))[1].node.publicURL || null,
    icon_titular: data.allFile.edges.filter(file => (file.node.name === 'icon-titular'))[1].node.publicURL || null,
    icon_aval: data.allFile.edges.filter(file => (file.node.name === 'icon-aval'))[1].node.publicURL || null,
    icon_moral: data.allFile.edges.filter(file => (file.node.name === 'icon-moral'))[1].node.publicURL || null,
    icon_legal: data.allFile.edges.filter(file => (file.node.name === 'icon-legal'))[1].node.publicURL || null,
    icon_especializacion:data.allFile.edges.filter(file => (file.node.name === 'icon-especializacion'))[1].node.publicURL || null,
    icon_especializacion_active:data.allFile.edges.filter(file => (file.node.name === 'icon-especializacion-active'))[0].node.publicURL || null,
    icon_flexibilidad:data.allFile.edges.filter(file => (file.node.name === 'icon-flexibilidad'))[1].node.publicURL || null,
    icon_flexibilidad_active:data.allFile.edges.filter(file => (file.node.name === 'icon-flexibilidad-active'))[0].node.publicURL || null,
    icon_experiencia:data.allFile.edges.filter(file => (file.node.name === 'icon-experiencia'))[1].node.publicURL || null,
    icon_experiencia_active:data.allFile.edges.filter(file => (file.node.name === 'icon-experiencia-active'))[0].node.publicURL || null,
    icon_agilidad:data.allFile.edges.filter(file => (file.node.name === 'icon-agilidad'))[1].node.publicURL || null,
    icon_agilidad_active:data.allFile.edges.filter(file => (file.node.name === 'icon-agilidad-active'))[0].node.publicURL || null,
    icon_atencion:data.allFile.edges.filter(file => (file.node.name === 'icon-atencion'))[0].node.publicURL || null,
    icon_atencion_active:data.allFile.edges.filter(file => (file.node.name === 'icon-atencion-active'))[0].node.publicURL || null,
    icon_user_active:data.allFile.edges.filter(file => (file.node.name === 'icon-user-active'))[1].node.publicURL || null,
    icon_user_inactive:data.allFile.edges.filter(file => (file.node.name === 'icon-user-inactive'))[0].node.publicURL || null,
    icon_building_active:data.allFile.edges.filter(file => (file.node.name === 'icon-building-active'))[1].node.publicURL || null,
    icon_building_inactive:data.allFile.edges.filter(file => (file.node.name === 'icon-building-inactive'))[0].node.publicURL || null,
    icon_car_active:data.allFile.edges.filter(file => (file.node.name === 'icon-car-active'))[0].node.publicURL || null,
    icon_car_inactive:data.allFile.edges.filter(file => (file.node.name === 'icon-car-inactive'))[0].node.publicURL || null,
    icon_stethoscope_active:data.allFile.edges.filter(file => (file.node.name === 'icon-stethoscope-active'))[0].node.publicURL || null,
    icon_stethoscope_inactive:data.allFile.edges.filter(file => (file.node.name === 'icon-stethoscope-inactive'))[0].node.publicURL || null,
    icon_bullet: data.allFile.edges.filter(file => (file.node.name === 'icon-bullet'))[1].node.publicURL || null,
    icon_peru: data.allFile.edges.filter(file => (file.node.name === 'icon-peru'))[1].node.publicURL || null,
    icon_mexico: data.allFile.edges.filter(file => (file.node.name === 'icon-mexico'))[1].node.publicURL || null,
  }

  const logos = {
    logo_youtube: data.allFile.edges.filter(file => (file.node.name === 'logo-youtube'))[1].node.publicURL || null,
    logo_spotify: data.allFile.edges.filter(file => (file.node.name === 'logo-spotify'))[0].node.publicURL || null,
    logo_facebook: data.allFile.edges.filter(file => (file.node.name === 'logo-facebook'))[1].node.publicURL || null,
    logo_android: data.allFile.edges.filter(file => (file.node.name === 'Mobile-app-store-android'))[0].node.publicURL || null,
    logo_ios: data.allFile.edges.filter(file => (file.node.name === 'Mobile-app-store-ios'))[0].node.publicURL || null,
  }


  const Pilares = [
    {text: 'Especialización', icon: <img src={icons.icon_especializacion}/>, icon_active: <img src={icons.icon_especializacion_active} />, active: true, description: 'Financiera líder en arrendamiento de equipo médico, más de mil quinientos médicos han adquirido o renovado sus equipos de la mano de Lease for U '},
    {text: 'Flexibilidad', icon: <img src={icons.icon_flexibilidad} />, icon_active: <img src={icons.icon_flexibilidad_active} />, active: false, description: 'Trabajamos conjuntamente con nuestros clientes para estudiar y diseñar soluciones innovadoras que vayan alineadas a su presupuesto y estrategia de negocio. Enfocados siempre en  aumentar la rentabilidad de nuestros clientes enfocándonos en la eficiencia y vida útil de sus activos.'},
    {text: 'Experiencia', icon: <img src={icons.icon_experiencia} />, icon_active: <img src={icons.icon_experiencia_active} />, active: false, description: 'Más de 1,500 clientes respaldan nuestra experiencia. Somos la única arrendadora cuyo equipo comercial cuenta con más de 30 años de experiencia en equipo médico de diversas especialidades, imagenología y diagnóstico, odontología, oftalmología, dermatología y medicina estética.'},
    {text: 'Agilidad', icon: <img src={icons.icon_agilidad} />, icon_active: <img src={icons.icon_agilidad_active} />, active: false, description: 'Entendemos que el tiempo de respuesta es crucial para la toma de decisiones, por lo que nos comprometemos a darte una resolución en un rango de 6 a 48 horas* *Expediente completo'},
    {text: 'Atención personalizada', icon: <img src={icons.icon_atencion} />, icon_active: <img src={icons.icon_atencion_active} />, description: 'Tenemos claro que detrás de cada proyecto hay una persona con deseos, presupuestos, objetivos y necesidades diferentes, por esta razón, empatizamos y trabajamos con cada cliente de manera personalizada y con un mismo objetivo: Crecer Juntos'},
  ]

  const gif = {
    mobile: data.allFile.edges.filter(file => (file.node.name === 'mobile_animation'))[0].node.publicURL || null,
    mobile_fija: data.allFile.edges.filter(file => (file.node.name === 'mobile_fija'))[0].node.publicURL || null,
    mobile_1:data.allFile.edges.filter(file => (file.node.name === 'image_1'))[0].node.publicURL || null,
    mobile_2:data.allFile.edges.filter(file => (file.node.name === 'image_2'))[0].node.publicURL || null,
    mobile_3:data.allFile.edges.filter(file => (file.node.name === 'image_3'))[0].node.publicURL || null,
    mobile_4:data.allFile.edges.filter(file => (file.node.name === 'image_4'))[0].node.publicURL || null,

  }
  console.log(gif.mobile)

  const countries_icons = {
    peru: icons.icon_peru,
    mexico: icons.icon_mexico,
  }

  const COUNTRY = process.env.GATSBY_COUNTRY
  console.log('COUNTRY: ', COUNTRY);
  return <>
    <Seo title="Home" />
    <Header logo={logo.node.publicURL} countries_icons={countries_icons} />
    <Home bg={bgHome} icons={icons}/>
    <Seo title="Cotizador" />
    <Cotizador bg={bgCotizador} icons={icons}/>
    <Proximamente gif={gif} logos={logos}/>
    <AboutUs />
    <Nosotros Pilares={Pilares} />
    { COUNTRY == 'PE'
      ? <RequisitosPeru bg={bgRequisitos.node.publicURL} icons={icons}/>
      : <Requisitos bg={bgRequisitos.node.publicURL} icons={icons}/>}
    <Comunidad logos={logos} icons={icons}/>
   
    <Footer icons={icons} />
  </>
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
