import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export default function Bullet({text, options = null, icons}) {
  return (
    <div className="bullet">
        <div className='bullet-text'> <img src={icons.icon_bullet} alt={'bullet'} style={{marginRight: '18px'}}/> {text}</div>
        {options ? <ul>
            {options.map( (item, index) => <li key={`bullet-${index}`}>{item}</li>)}
        </ul> : <></>}
      
    </div>
  )
}
