import React from 'react'
import { PilarBoxStyle, FillText, NormalText } from '../../styles'

export default function Pilar({id, text, icon, icon_active, active}) {
  return (
    <PilarBoxStyle>
      {active ? icon_active : icon}
      {active ? <FillText className={"pilar"} id={`option-${id}`} key={`option-${id}`}>{text}</FillText> : <NormalText className={"pilar"} id={`option-${id}`} key={`option-${id}`}>{text}</NormalText>}
    </PilarBoxStyle>
  )
}
