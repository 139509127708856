import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import { HeaderSectionWhite, RequisitosStyle } from '../../styles'
import { StaticImage } from 'gatsby-plugin-image';
import RequisitoSectionTitle from '../custom/requisitos-section';
import * as utils from './../../utils/index'


import { styled } from '@mui/material/styles';
import Bullet from '../custom/bullet';
import { red } from '@mui/material/colors';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export default function RequisitosPeru({bg, icons}) {
    const [value, setValue] = React.useState(0);
    const [PersonaFisicaImage, setValuePersonaFisica] = React.useState(<img src={icons.icon_user_active} />)
    const [PersonaMoralImage, setValuePersonaMoral] = React.useState(<img src={icons.icon_building_inactive} />)
    const [withCIEC, setWithCIEC] = React.useState(false)

    const IOSSwitch = styled((props) => {
      //console.log(props);
        return <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      })(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: '#00A9FF',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
          backgroundColor: 'white',
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: '#C0C8CE',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

    const handleChange = (event, newValue) => {
      setValue(newValue);
     
      if(newValue === 1){
        setValuePersonaMoral(<img src={icons.icon_building_active} />)
        setValuePersonaFisica(<img src={icons.icon_user_inactive} />)
        
 
      }else {
        setValuePersonaMoral(<img src={icons.icon_building_inactive} />)
        setValuePersonaFisica(<img src={icons.icon_user_active} />)
      }
    };
  return (
    <RequisitosStyle id="requisitos">
      <HeaderSectionWhite>Requisitos del arrendamiento</HeaderSectionWhite>
      <Box sx={{ width: '100%' }} className="TabsSection">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                <Tab label="Persona Natural" icon={PersonaFisicaImage} iconPosition="start" {...a11yProps(0)} />
                <Tab label="Persona Jurídica" icon={PersonaMoralImage} iconPosition="start" {...a11yProps(1)} />
            </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          
            {/* <div className='CIEC'>
                <IOSSwitch onChange={(a) => setWithCIEC(a.target.checked)} checked={withCIEC}/>
                <p>¡Tu trámite con tu identificación CIEC es más fácil!</p>
            </div> */}
            <div className={'requisitos-panel'} style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <div>
            <RequisitoSectionTitle text="Titular"><img src={icons.icon_titular}/></RequisitoSectionTitle>
                <Bullet text={'Solicitud de arrendamiento.'} icons={icons}/>
                <Bullet text={'Contacto/facturación debidamente llenada.'} icons={icons}/>
                <Bullet text={'Copia de DNI vigente y legible.'} icons={icons}/>
              <Bullet text={'Copia del comprobante de domicilio*'} options={[
                'Del titular.',
                'Donde se encontrará el activo.'
              ]}
              icons={icons}/>
                <Bullet text={'RUC (Registro Único de Contribuyentes).'} icons={icons}/>
                <Bullet text={'Estados de cuenta y/o recibos de nómina de los últimos 3 meses.'} icons={icons}/>
               
            <div className="req-aval" style={{
                width: '100%',
                // minHeight: '300px',
            }}>
            <RequisitoSectionTitle text="Aval"><img src={icons.icon_aval}/></RequisitoSectionTitle>
              <Bullet text={'Copia de DNI vigente y legible.'} icons={icons} />
              <Bullet text={'Copia del comprobante de domicilio.'} icons={icons}/>
              <Bullet text={'RUC (Registro Único de Contribuyentes).'} icons={icons}/>
                <p className='emision' style={{
                  
                 
                }}>* Emisión menor a 3 meses</p>
            </div>
            </div>
          
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
            {/* <div className='CIEC'>
                <IOSSwitch onChange={(a) => setWithCIEC(a.target.checked)} checked={withCIEC} />
                <p>¡Tu trámite con tu identificación CIEC es más fácil!</p>
            </div> */}

            <RequisitoSectionTitle text="Información financiera y cualitativa de la empresa:"><img src={icons.icon_moral}/></RequisitoSectionTitle>
              <Bullet text={'Solicitud de arrendamiento.'} icons={icons}/>
              <Bullet text={'Contacto/facturación debidamente requisitada con firma autógrafa del Representante Legal en original.'} icons={icons}/>
              <Bullet text={'RUC (Registro Único de Contribuyentes)*'} icons={icons}/>
              <Bullet text={'Estados financieros auditados.'} options={[
                'Últimos 2 ejercicios anuales completos y  parcial del ejercicio en curso.',
                'Con firma autógrafa del Representante Legal ',
                'y contador acompañados con sus respectivas notas.',
                '(En caso de no auditar, enviar internos con relación de analíticas).',
              ]} icons={icons} />
              <Bullet text={'Los 3 PDT del año en curso.'} options={[
                'Con sus últimos pagos de IGV / Renta a SUNAT',
              ]} icons={icons}/>
              <Bullet text={'Declaración Jurada Anual.'} options={[
                'Últimos 2 ejercicios anuales completos y  parcial del ejercicio en curso.',
                'Pago o fraccionamiento del impuesto ANUAL.'
              ]} icons={icons} />
              <Bullet text={'Últimos 3 estados de cuenta de cheques de sus principales bancos.'} options={[
                'Completos y con cadena digital.',
                'No se aceptan detalles de movimientos ni estado de cuenta de inversiones.'
              ]} icons={icons} />

            <RequisitoSectionTitle text="Información legal:"><img src={icons.icon_legal}/></RequisitoSectionTitle>
              <Bullet text={'Copia de DNI*'} icons={icons}/>
              <Bullet text={'Copia del Testimonio de la empresa.'} icons={icons}/>
              <Bullet text={'Vigencia de poderes y copita literal de la persona que tenga poderes para comprar activos.'} options={[
                'Con antigüedad no mayor a 1 mes.',
              ]}
              icons={icons}/>
              <Bullet text={'Copia de comprobante de domicilio*'} options={[
                'Representante legal.',
                'Empresa.',
                'Preferentemente de teléfono',
                'Donde se encontrará el activo.'
              ]}
              icons={icons}/>

            <RequisitoSectionTitle text="Aval y/u Obligado solidario:"><img src={icons.icon_aval}/></RequisitoSectionTitle>
            <p className='nota-aval'><span className='text-span'>Persona Jurídica:</span> Deberá presentarse la información financiera, cualitativa de la empresa y la información legal contenidas en los puntos que anteceden. <br/><span className='text-span'>Persona Física:</span></p>
              <Bullet text={'Copia de DNI*'} icons={icons}/>
              <Bullet text={'Copia del comprobante de domicilio*'} options={[
                'Representante legal.',
                'Empresa.',
                'Preferentemente de teléfono',
                'Donde se encontrará el activo.'
              ]} icons={icons}/>
            {/* <StaticImage src='../../images/img-persona-moral.png' /> */}
            <p className='emision' style={{
                  
                }}>*Emisión menor a 3 meses</p>
        </TabPanel>
    </Box>
    </RequisitosStyle>
  )
}
