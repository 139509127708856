import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import { H2HomeStyle, HomeStyle, StoreIcons, NetworkSocialIcons, Button } from '../../styles';

export default function Home({bg = null, icons}) {





  return (
    <HomeStyle className="HomeStyle" id='home'>
        
        <div  style={{
            width: '100%',
            backgroundImage: bg ? `url(${bg.node.publicURL})` : '',
        }} className="img-back-home">
            <div className="btnWhatsapp">
                <Link to="https://wa.me/525518326853?text=¡Hola!" target="_blank"><img src={icons.btn_wa} alt="whatsapp" /></Link>
            </div>
            {/* <div className="counter">
                <Button>3,000 clientes</Button>
                <span>Confian en nosotros</span>
            </div> */}
            <h2>Tú lo sueñas,<br />nosotros lo volvemos<br />una realidad.</h2>
            <p>Muéstranos hacia dónde vas<br />y déjanos recorrer el camino contigo.</p>
            <div className="StoreIcons">
               <Link to='https://play.google.com/store/apps/details?id=com.leaseforu.uweb' > <img className="btn" src={icons.btn_playstore} alt="btn-playstore"/> </Link>
               <Link to='https://apps.apple.com/mx/app/leaseforu/id1530250409'> <img className="btn" src={icons.btn_appstore} alt="btn-appstore"/> </Link>
                {/* <img className="btn" src={icons.btn_appgallery} alt="btn-appgallery"/> */}
            </div>
            <p className='text-redes'>Encuéntranos también en los medios</p>
            <div className="NetworkSocialIcons">
                <Link to="https://www.facebook.com/profile.php?id=100064230634432" target="_blank" ><img src={icons.btn_facebook} alt="btn-facebook" /></Link>
                <Link to="https://twitter.com/lease_for_u" target="_blank" ><img src={icons.btn_twitter} alt="btn-twitter" /></Link>
                <Link to="https://www.linkedin.com/company/lease-for-u/mycompany/" target="_blank" ><img src={icons.btn_linkedin} alt="btn-linkedin" /></Link>
                <Link to="https://open.spotify.com/episode/6Mp9QjdZCDRoIfQouqKnFv?si=5dlz0XpvQkGeceGM8g9zZg" target="_blank" ><img src={icons.btn_spotify} alt="btn-spotify" /></Link>
                {/* <StaticImage src='../../images/btn-whatsapp.png'  alt="whatsapp" /> */}
                {/* <StaticImage src='../../images/btn-facebook.png'  alt="facebook" /> */}
                {/* <StaticImage src='../../images/btn-instagram.png'  alt="instagram" /> */}
                {/* <StaticImage src='../../images/btn-twitter.png'  alt="twitter" /> */}
                {/* <StaticImage src='../../images/btn-tiktok.png'  alt="tiktok" /> */}
                {/* <StaticImage src='../../images/btn-spotify.png'  alt="spotify" /> */}
            </div>
        </div>
    </HomeStyle>
  )
}
