import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { AboutUsStyle, HeaderSectionWhite } from "../../styles"

export default function AboutUs() {
  const width = 300
  return (
    <AboutUsStyle id="nosotros">
      <HeaderSectionWhite>Quiénes somos</HeaderSectionWhite>
      <div className="quienEsLFU">
      <p>
        <span>Lease for U</span> es una arrendadora especializada en el sector médico, 
        además de ser una empresa que ofrece una alternativa{" "}
        <span>ágil, versátil</span>{" "}y<span>{" "}personalizada{" "} </span> mediante
        nuestros productos de arrendamiento.
      </p>
      <p>
        Apoyamos a las personas y empresas con soluciones
        financieras para que puedan obtener y utilizar activos que les permitan {" "}<span>
        crecer en su profesión {" "}</span> y alcanzar sus metas profesionales, financieras y
        personales.
      </p>
      </div>
      {/* <div className="img-quienEsLFU" style={{display: "flex", justifyContent: "center"}}>
        <StaticImage src="../../images/mexico-lfu.png"  style={{ maxHeight: '367px', maxWidth: '549px' }}  alt="quien-es-LfU"/>
      </div> */}
    </AboutUsStyle>
  )
}
