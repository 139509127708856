import React from 'react'
import { CardStyle } from '../../styles'

export default function Card({className, image, title, text, author = null, height = '200px'}) {
  return (
    <CardStyle className={className} style={{
        height
    }}>
        {image}
        <p style={{
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '24px',
        }}>{title}</p>
        <p>{text}</p>
        {author
        ? <p style={{
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '24px',
        }}>{author}</p>
        : <></>
    }
        
    </CardStyle>
  )
}
