import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { ComunidadStyle, HeaderSectionWhite } from "../../styles"
import Card from "../custom/card"
import RequisitoSectionTitle from "../custom/requisitos-section"

import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination, Navigation } from "swiper"
import { Link } from "gatsby"

export default function Comunidad({ logos, icons }) {
  let isMobile = false

  if (typeof window !== "undefined") {
    isMobile = window.innerWidth <= 799
  }
  const Comentarios = [
    {
      text: "“Excelente la atención de su asesor comercial, siempre disponible, atento”",
      author: "LO&T Clínica de Rehabilitación",
    },
    {
      text: "“Me estuvieron ayudando en todo, orientándome, estoy muy agradecido”",
      author: "MVZ. Luis Cruz Bacab",
    },
    {
      text: "“Son muy amables,despejaron mis dudas,que eran muchas y fueron siempre pacientes”",
      author: "Dra. Ivette Calderón",
    },
    {
      text: "“Estoy muy contento con ustedes, son una empresa seria que cumple lo que promete”",
      author: "Dr. Alan Arvizu",
    },
    {
      text: "“Gracias por la confianza de apoyarme en mi actividad, financiando el equipo, que sigan cosechando mucho éxito”",
      author: "Dr. Julio Avendaño",
    },
    {
      text: "“Es un gusto trabajar con ustedes, siempre amables, proactivos, sin duda seguiré trabajando con ustedes”",
      author: "Dra. María del Rocío Iñiguez",
    },
    {
      text: "“Gracias por hacer realidad mi sueño de independizarme, por confiar en mí a pesar de ser extranjera”",
      author: "Dr. Deliber Molina",
    },
    {
      text: "“Fue rápido, la verdad es que estoy muy contento con todo, el trato, los tiempos, el servicio, no tuve que batallar para nada”",
      author: "Dr. Antonio Casanova",
    },
    {
      text: "“La atención, el servicio, la rapidez, definitivamente se sacan un 10”",
      author: "Dra. Daniela Torres",
    },
  ]
  return (
    <ComunidadStyle id="comunidad">
      <HeaderSectionWhite>Conoce nuestra comunidad</HeaderSectionWhite>

      <Swiper
        slidesPerView={isMobile ? "auto" : 3}
        spaceBetween={30}
        centeredSlides={false}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          {/* <StaticImage src="../../images/logo-youtube.svg" style={{marginBottom: '16px'}}/>
                <Link to='https://www.youtube.com/watch?v=suX_HvROCJ8' target="_blank" style={{textDecoration: "none"}}><Card className="cards-network" image={<StaticImage src="../../images/banner-youtube.png" alt="youtube"/>} title="#GritoBIVA - Lease For U" text="#EnVivo ¡Conéctate ahora y grita con nosotros<br />#BIVALeaseForU! Te invitamos a dar el #GritoBIVA en conjunto con @Lease For U para darle la bienvenida a la #FamiliaBIVA."  height='320px'/></Link> */}
          <img
            src={logos.logo_youtube}
            alt="btn-logo"
            style={{ marginBottom: "16px" }}
          />
          <Link
            to="https://www.youtube.com/watch?v=suX_HvROCJ8"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Card
              className="cards-network"
              image={
                <StaticImage
                  src="../../images/banner-youtube.png"
                  alt="youtube"
                />
              }
              title="#GritoBIVA - Lease for U"
              text="#EnVivo ¡Conéctate ahora y grita con nosotros<br />#BIVALeaseForU! Te invitamos a dar el #GritoBIVA en conjunto con @Lease for U para darle la bienvenida a la #FamiliaBIVA."
              height="360px"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
        <img
            src={logos.logo_spotify}
            alt="btn-spotify"
            style={{ marginBottom: "16px" }}
          />
          <Link
            to="https://open.spotify.com/episode/6Mp9QjdZCDRoIfQouqKnFv?si=5dlz0XpvQkGeceGM8g9zZg"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            {" "}
            <Card
              className="cards-network"
              image={
                <StaticImage
                  src="../../images/banner-spotify.png"
                  alt="spotify"
                />
              }
              title="Ep.130: Entrevista a Ariel Fainsod fundador y CEO de Lease for U"
              text="Ingeniero Electrónico egresado de la Universidad Iberoamericana con una MBA por Darden Business School..."
              height="360px"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
        <img
            src={logos.logo_facebook}
            alt="btn-facebook"
            style={{ marginBottom: "16px" }}
          />
          <Link
            to="https://www.facebook.com/profile.php?id=100064230634432"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Card
              className="cards-network"
              image={
                <StaticImage src="../../images/banner-facebook.png" alt="fb" />
              }
              text="El 60% de los adultos mayores, en México, sufre algún tipo de discapacidad. A través de una evaluación se determinará el estado de las habilidades funcionales y cognitivas, así como la salud física y mental de estos. Adquiere el equipo necesario para realizar  diagnósticos que permitan determinar..."
              height="360px"
            />
          </Link>
        </SwiperSlide>
      </Swiper>

      {/* <RequisitoSectionTitle text="Comentarios de nuestros clientes">
        <img src={icons.icon_titular} alt="titular" />
      </RequisitoSectionTitle>

      <Swiper
        slidesPerView={isMobile ? "auto" : 3}
        spaceBetween={30}
        centeredSlides={false}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {Comentarios.map((comentario, index) => (
          <SwiperSlide key={`slide-${index}`}>
            <Card
              className="cards-comments"
              text={comentario.text}
              author={comentario.author}
            />
          </SwiperSlide>
        ))}
      </Swiper> */}
    </ComunidadStyle>
  )
}
