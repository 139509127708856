import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';

import Select from 'react-select'
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import { ButtonNormal, CotizadorStyle, FormStyle, FormStyleCotizador, HeaderSectionWhite, InputSelectStyle } from '../../styles'
import TextNumber from '../custom/text_number'
import { Link } from 'gatsby';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { StaticImage } from 'gatsby-plugin-image';
import { width } from '@mui/system';

let _timeOut = null
const CURRENCY = process.env.GATSBY_CURRENCY
const AMOUNT = Number(process.env.GATSBY_AMOUNT)
const MIN_AMOUNT = Number(process.env.GATSBY_MIN_AMOUNT)
const MAX_AMOUNT = Number(process.env.GATSBY_MAX_AMOUNT)
const INIT_AMOUNT = Number(process.env.GATSBY_INIT_AMOUNT)

const url = process.env.GATSBY_PORTAL_DOMAIN
//const url = 'http://localhost:4200/'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function Cotizador({bg = null, icons}) {

  const [wait, setWait] = useState(false);
  const [amount, setAmount] = useState(AMOUNT);
  const [init_amount, setInitAmount] = useState(INIT_AMOUNT)
  const [init_min_amount, setInitMinAmount] = useState(init_amount)
  const [init_max_amount, setInitMaxAmount] = useState(amount * 0.70)
  const [init_percent, setInitPercent] = useState(20)
  const [periods, setPeriods] = useState({ value: 'ap12', label: '12 mensualidades'})
  const [period, setPeriod] = useState(12)
  const [payment, setPayment] = useState(11627.74)
  const [isloading, setIsloading] = useState(false)
  const [residual, setResidual] = useState(0)
  const [tipo_arrendamiento, setTipoArrendamiento] = useState({value: 'equipo_medico', label: 'Equipo médico'})

  const [value, setValue] = React.useState(0);
  const [PersonaFisicaImage, setValuePersonaFisica] = React.useState(<img src={icons.icon_stethoscope_active} />)
  const [PersonaMoralImage, setValuePersonaMoral] = React.useState(<img src={icons.icon_car_inactive} />)
  // let amount = 100000

  const tipos_arrendamiento = [{value: 'equipo_medico', label: 'Equipo médico'}, {value: 'auto', label: 'Auto'}]
  const plazos = [
    { value: 'ap12', label: '12 mensualidades'},
    { value: 'ap18', label: '18 mensualidades'},
    { value: 'ap24', label: '24 mensualidades'},
    { value: 'ap36', label: '36 mensualidades'},
    { value: 'ap48', label: '48 mensualidades'},
    { value: 'ap60', label: '60 mensualidades'},
  ]
  const marks = [
    { value: '12', label: ''},
    { value: '18', label: ''},
    { value: '24', label: ''},
    { value: '36', label: ''},
    { value: '48', label: ''},
    { value: '60', label: ''},
  ]


  const Actualizar = () => {
    setWait(true)
    clearTimeout(_timeOut)
    _timeOut = setTimeout(ObtenerMensualidad, 2000)
  }

  const ObtenerMensualidad = async (period = null) => {
    console.log(amount, init_percent, period ? period : periods.value);
    setIsloading(true)
    console.log('tasa: ', process.env.GATSBY_TASA);
    const data = {
      "factor": 16.5,
      "preview":true,
      "only_monthly_payment": true,
      "user_id":79,
      "vendor_id":79,
      "client_name":"",
      "client_first_lastname":"",
      "client_second_lastname":"",
      "client_email":"",
      "client_phone":"",
      "blind_discount":0,
      "vendor_payment":0,
      "delivery_time":0,
      "msi_initial_payment":0,
      "precioSeguroMensualFinanciado":98,
      "sin_producto":true,
      "discount_amount":0,
      "residuales": tipo_arrendamiento.value === 'auto' 
        ? {"ap12":40,"ap18":35,"ap24":30,"ap36":25,"ap48":20,"ap60":15}
        : {"ap12":0,"ap18":0,"ap24":0,"ap36":0,"ap48":0,"ap60":0},
      "send":false,
      "amount": amount,
      "initial_payment":Number((((init_amount * 100) / amount) / 100).toFixed(4)),
      "periods": period ? period : periods.value,
    }

    const resp = await fetch('https://dev1.api.leaseforu.com/cotizador/nueva', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })

    const {arrendamiento_puro} = await resp.json();
    const {monthly_payment, residual_amount} = arrendamiento_puro[0]

    console.log(monthly_payment, residual_amount);
    setPayment(monthly_payment)
    setResidual(residual_amount)
    setIsloading(false)
    setWait(false)
  }

  const CurrencyFormat = (num) => {
    return '$ ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ` ${CURRENCY}`
  }

  const ChangeValues = async ({value_amount, value_init_amount}) => {
    console.log({value_amount, value_init_amount});
    console.log(`Min amount: ${value_amount * 0.2}`);
    console.log(`Max amount: ${value_amount * 0.7}`);
    if (value_amount) {
      setAmount(value_amount)
      setInitPercent(20)
      setInitAmount(value_amount * 0.20)
      setInitMinAmount(value_amount * 0.20)
      setInitMaxAmount(value_amount * 0.70)
    }

    if (value_init_amount) {
      const percent = value_init_amount

      console.log(`Percent: ${percent}`);

      // const initPercent = ((value_init_amount * 100) / amount).toFixed(2)
      setInitPercent(percent)
      setInitAmount((percent * amount) /100)
    }
  }

  const ChangePeriod = async (period) => {
    console.log(period);
    setPeriod(period)
    setPeriods({value: 'ap'+period, label: period+' mensualidades'})
    Actualizar(period.value)
  }

  const ChangeTipoArrendamiento = (tipo) => {
    setTipoArrendamiento(tipo)
  }


  const handleChange = (event, newValue) => {
    setValue(newValue);

    newValue === 0
      ? setValuePersonaFisica(<img src={icons.icon_stethoscope_active}/>)
      : setValuePersonaFisica(<img src={icons.icon_stethoscope_inactive}/>)

    newValue === 1
      ? setValuePersonaMoral(<img src={icons.icon_car_active}/>)
      : setValuePersonaMoral(<img src={icons.icon_car_inactive}/>)

      setTipoArrendamiento(newValue === 0 ? {value: 'equipo_medico', label: 'Equipo médico'} : {value: 'auto', label: 'Auto'})
  };




  useEffect(() => {
    console.log(tipo_arrendamiento);
    ObtenerMensualidad()
  }, [tipo_arrendamiento])
  return (
    <CotizadorStyle id="cotizador">
      <HeaderSectionWhite>Realiza una cotización</HeaderSectionWhite>
      <Box sx={{ width: '100%' }} className="TabsSection">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                <Tab label="Equipo médico" icon={PersonaFisicaImage} iconPosition="start" {...a11yProps(0)}/>
                {/* <Tab label="Auto" icon={PersonaMoralImage} iconPosition="start" {...a11yProps(1)} /> */}
            </Tabs>
        </Box>
        
    </Box>
      <div style={{
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center 20px',
        backgroundSize: 'auto 350px',
        backgroundImage: bg ? `url(${bg.node.publicURL})` : '',
        padding: '40px 0',
      }}>
        {/* <TextNumber number={1} text={'Completa los datos'} /> */}

        <FormStyleCotizador>
          {/* <InputSelectStyle>
            <span>Tipo de arrendamiento</span>
            <Select className='input-select'
              styles={{
                border: 'none',
                size: '20px',
              }}
              isSearchable={false}
              isClearable={false}
              value={tipo_arrendamiento}
              onChange={(input) => ChangeTipoArrendamiento(input)}
              options={tipos_arrendamiento}
            />
          </InputSelectStyle> */}

          <InputSelectStyle style={{
            height: '100px',
           
          }}>
            <span>Precio del {tipo_arrendamiento.label.toLowerCase()}</span>
            <p>{CurrencyFormat(amount)}</p>
            <Slider
              key={'slider-amount'}
              defaultValue={INIT_AMOUNT}
              step={1000}
              min={MIN_AMOUNT}
              max={MAX_AMOUNT}
              onChange={ (event, newValue, activeThumb) => ChangeValues({value_amount: newValue, value_init_amount: null})}
              onChangeCommitted={() => Actualizar()}
            />
          </InputSelectStyle>

          <InputSelectStyle style={{
            height: '100px',
          }}>
            <span>Enganche</span>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <p>{CurrencyFormat(init_amount)}</p>
              <p className='percent'>{init_percent} %</p>
            </div>
            <Slider
              key={'slider-init_amount'}
              defaultValue={20}
              step={1}
              min={20}
              max={60}
              value={init_percent}
              onChangeCommitted={() => Actualizar()}
              onChange={ (event, newValue, activeThumb) => ChangeValues({value_amount: null, value_init_amount: newValue})}
            />
          </InputSelectStyle>
          <InputSelectStyle  class='row-slider' style={{
            height: '100px',
           
          }}>
            <span>Plazo a financiar</span>
            {/* <Select className='input-select'
              styles={{
                border: 'none',
                size: '20px',
              }}
              isSearchable={false}
              isClearable={false}
              value={periods}
              onChange={(input) => ChangePeriod(input)}
              options={plazos} /> */}

              <p>{period} mensualidades</p>
              <Slider
                key={'slider-amount'}
                defaultValue={12}
                step={null}
                min={12}
                max={60}
                marks={marks}
                onChange={ (event, newValue, activeThumb) => ChangePeriod(newValue)}
                onChangeCommitted={() => Actualizar()}
              />
          </InputSelectStyle>
        </FormStyleCotizador>

        {/* <TextNumber number={2} text={'Conoce tu pago'} /> */}
        <FormStyle style={{justifyContent: 'center'}}>
        

          <InputSelectStyle  style={{
            height: '100px',
            textAlign: 'center'
          }}>
            <span>Monto a pagar mensualmente</span>
            <p class="monto-mensual" >{ wait ? <CircularProgress color='primary' style={{textAlign: 'center'}} size={20} />  : CurrencyFormat(payment)} 
              {/* {isloading ? <CircularProgress color='primary' style={{marginLeft: '30px'}} size={20} /> : <></>} */}
            </p>
          </InputSelectStyle>

            {tipo_arrendamiento.value === 'equipo_medico' ? <></> :   
              <InputSelectStyle style={{
                height: '100px'
              }}>
                <span>Monto de valor residual</span>
                <p class="monto-mensual" >{CurrencyFormat(residual)} 
                  {isloading ? <CircularProgress color='primary' style={{marginLeft: '30px'}} size={20} /> : <></>}
                </p>
              </InputSelectStyle>
            }

        </FormStyle>
        
        <div>
            <p className='condicion'>{tipo_arrendamiento.value === 'equipo_medico' ? 'Seguro incluido*. ' : 'Seguro y trámites vehículares no incluidos*. '}</p>
        </div>
        
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px'
      }}>
        {/* <ButtonNormal>¡Solicítalo ahora!</ButtonNormal> */}
        {/* <Link style={{textDecoration: 'none'}} to={`${url}#/mejorar_cotizacion?tipo_producto=${tipo_arrendamiento.label}&monto=${amount}&plazo=${period}&pago_inicial=${init_amount}&mensualidad=${payment}`}><ButtonNormal>¡Mejora la cotización!</ButtonNormal></Link> */}
      </div>
    </CotizadorStyle>
  )
}
