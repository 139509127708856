import { red } from '@mui/material/colors'
import React from 'react'
import { SectionTitleStyle } from '../../styles'

export default function RequisitoSectionTitle({children, text}) {
  return (
    <SectionTitleStyle className="section-title">
        {children}
        <p>{text}</p>
    </SectionTitleStyle>
  )
}
