
import React, {useState} from 'react'
import { HeaderSectionBlue, LiderBoxStyle, NosotrosStyle } from '../../styles'
import Pilar from '../custom/pilar'

export default function Nosotros({Pilares}) {
    const [options, setOptions] = useState(Pilares)
    const [description, setDescription] = useState(Pilares[0].description)

    const handleChange = (i) => {
        const pilares = options.map( (pilar, index) => {
            pilar.active = false
            if (index === i) {
                pilar.active = true
                setDescription(pilar.description)
            }
            return pilar
        })
        setOptions(pilares)
    }
  return (
    <NosotrosStyle >
        <HeaderSectionBlue>¿Por qué elegir a Lease for U?</HeaderSectionBlue>
        <p>En Lease for U nos distinguimos por buscar relaciones sólidas y de largo plazo con nuestros clientes, con base en cinco pilares:</p>
        <div className="fortalezas" style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginBottom: '24px',
        }}>
            {options.map( (pilar, index) => <div key={`Pilar-${index}`} onClick={() => handleChange(index)}><Pilar text={pilar.text} icon={pilar.icon} icon_active={pilar.icon_active} active={pilar.active} id={index} key={index} /></div>)}
        </div>
        <LiderBoxStyle>{description}</LiderBoxStyle>
    </NosotrosStyle>
  )
}
